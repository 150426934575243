<template>
  <NcmForm
    :ncm="ncm"
    :type="'create'"
    @save="createNcm"
  />
</template>

<script>
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    NcmForm: () => import('./NcmForm'),
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    isFormValid: true,
    askToLeave: true,
    ncm: {
      status: true,
      title: null,
      code: null,
      description: null,
    },
  }),
  methods: {
    createNcm() {
      const formData = new FormData()
      const ncmKeys = Object.keys(this.ncm)
      const ncmValues = Object.values(this.ncm)

      for (let i = 0; i < ncmKeys.length; i++) {
        if (ncmValues[i] !== null) {
          formData.append(ncmKeys[i], ncmValues[i])
        }
      }

      if (this.isFormValid) {
        this.askToLeave = false
        this.$api
          .post('ncms', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'NCM cadastrado com sucesso',
              confirm: () => {
                this.$router.push({ name: 'ncm' })
              },
            })
          })
          .catch(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Ops! Falha ao tentar cadastrar este NCM',
              caption: 'Verifique se os campos estão preenchidos corretamente.',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })
          })
      } else {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar cadastrar este NCM',
          caption: 'Verifique se os campos estão preenchidos corretamente.',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
